import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchFarmManagedStore = defineStore('searchFarmManagedStore', {
  state: () => ({
    Farm_id: null as number | null,
    useTerms: true,
    items: [{ key: 'Farm_id', operation: ':' }] as TermItemDefinition[]
  })
})
