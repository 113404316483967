<template>
  <div>
    <h2 v-html="$t('search_farm_title')"></h2>
    <!-- eigene Produktionsbetriebe -->
    <v-select
      id="search_farm_id"
      v-model="searchCardStore.Farm_id"
      :label="$t('search_farm_agis_id')"
      :items="farms"
      item-title="agisId"
      item-value="id"
      clearable
      @keyup.enter="search"
    />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { apiURL } from '@/main'
import { showError } from '@/services/axios'
import { useLegalEntityStore } from '@/store/LegalEntityStore'
import { useSearchFarmManagedStore } from '@/store/SearchFarmManagedStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchMyFarm',
  extends: baseSearchVue,
  data() {
    return {
      farms: []
    }
  },
  methods: {
    async getFarms() {
      let legalEntityStore = useLegalEntityStore()
      let url = apiURL + '/legalEntities/' + legalEntityStore.getSelectedPersonId + '/farms'
      try {
        const response = await this.axios.get(url)
        this.farms = await response.data
      } catch (e) {
        showError(e)
      }
    }
  },
  async beforeMount() {
    this.setSearchCardStore(useSearchFarmManagedStore(), true)
    await this.getFarms()
  }
})
</script>
